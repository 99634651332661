.home-section{
  &:nth-of-type(2n + 1){
    background-color: transparent;
    border-top: 2px solid $riihimaki-light;
    border-bottom: 2px solid $riihimaki-light;
  }

  &:nth-of-type(1){
    border-top: 0;
    border-bottom: 0;
  }
}

.subhero-cta{
  &:hover,
  &:focus{
    text-decoration: none;
    color: $riihimaki-link-hover;
  }
}

.home-stats__highlight{
  .section-statistics{
    width: 100%;
  }

  .statistic__data{
    &,
    &:nth-child(odd){
      border: 2px solid $white;
    }
  }

  .statistics-cell{
    .statistic__title{
      font-size: 1.8rem;
    }

    .statistic__data{
      padding: 2rem;
    }
  }
}
