.statistics-cell{
  .statistic__title{
    text-transform: none;
    font-size: 1.2rem;
  }

  .statistic__separator{
    width: 2rem;
    height: 2rem;
    margin: 1rem 0;
    align-self: center;
  }

  .statistic__data{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    background: $riihimaki-main;
    border: 2px solid $white;
    padding: 1rem;
    margin: 0;

    @include breakpoint(medium){
      width: 50%;
    }

    @include breakpoint(large){
      width: 25%;
    }

    &::after{
      display: none;
    }

    &:nth-child(2),
    &:nth-child(7){
      background: $riihimaki-sunflower;
    }

    &:nth-child(3),
    &:nth-child(8){
      background: $riihimaki-robot;
    }

    &:nth-child(4),
    &:nth-child(9){
      background: $riihimaki-main;
    }

    &:nth-child(5),
    &:nth-child(10){
      background: $riihimaki-park;
    }

    &,
    .statistic__title{
      color: $white;
    }

    .statistic__separator{
      fill: $white;
    }

    &:nth-child(2),
    &:nth-child(7){
      &,
      .statistic__title{
        color: $riihimaki-pool;
      }

      .statistic__separator{
        fill: $riihimaki-pool;
      }
    }

    &:nth-child(3),
    &:nth-child(8){
      &,
      .statistic__title{
        color: $riihimaki-park;
      }

      .statistic__separator{
        fill: $riihimaki-park;
      }
    }

    &:nth-child(4),
    &:nth-child(9){
      &,
      .statistic__title{
        color: $riihimaki-sunflower;
      }

      .statistic__separator{
        fill: $riihimaki-sunflower;
      }
    }

    &:nth-child(5),
    &:nth-child(10){
      &,
      .statistic__title{
        color: $riihimaki-pool;
      }

      .statistic__separator{
        fill: $riihimaki-pool;
      }
    }
  }
}
