a{
  &:hover{
    text-decoration: none;
  }
}

.section-heading{
  font-weight: $header-font-weight;
  text-transform: none;
  letter-spacing: 0;

  @each $size, $values in $header-styles{
    @include breakpoint($size){
      $header-defs: map-get($values, h3);

      font-size: rem-calc(map-get($header-defs, font-size));
    }
  }

  &::before{
    display: none;
  }
}
