button.link{
  cursor: pointer;
}

.button.hollow{
  &,
  &.secondary{
    &:hover,
    &:focus{
      background-color: $button-background;
      border-color: $button-background;
      color: $riihimaki-white;
    }
  }
}

a.button{
  &,
  &:hover,
  &:focus{
    transition: $button-transition;
  }
}

.button--sc {
  letter-spacing: normal;
  text-transform: none;
}

.button.muted{
  &,
  &:hover,
  &:focus{
    background-color: #ebebeb;
    color: $muted;
    filter: none;
  }
}
