.hero{
  background-position: center center;

  &::before{
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 20px;
    bottom: 20px;
    width: 65px;
    height: 90px;
    background-image: url(images/rsign-puzzle.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;

    @include breakpoint(medium){
      width: 109px;
      height: 150px;
    }
  }

  &::after{
    background-color: rgba($black, .4);
  }
}
