@import "../riihimaki-variables";

//  Foundation Settings Overrides
//  -----------------------------
//

// 1. Global
// ---------
$light-gray: #fafafa;
$medium-gray: #d1d1d1;
$dark-medium-gray: #3d393c;
$dark-gray: #2c2930;
$black: $riihimaki-black;
$white: $riihimaki-white;
$light-gray-dark: darken($light-gray, 2.5);
$body-background: $white;
$body-font-family: "Open Sans", sans-serif;
$global-weight-bold: 600;
$info-font-family: "Open Sans", sans-serif;
$global-radius: 0;

// 4. Base Typography
// ------------------

$header-font-family: Raleway, sans-serif;
$header-font-weight: 700;
$keystroke-radius: $global-radius;
$anchor-color: $riihimaki-link;
$anchor-color-hover: $riihimaki-link-hover;

// 11. Button
// ----------

$button-radius: $global-radius;
$button-hollow-border-width: 2px;
$button-transition: background-color .25s ease-out, color .25s ease-out, border-color .25s ease-out;
$button-brightness-hover: 120%;
$button-brightness-active: 120%;
$button-brightness-focus: 120%;

// 14. Card
// --------

$card-border-radius: $global-radius;

// 17. Dropdown
// ------------

$dropdown-radius: $global-radius;

// 20. Forms
// ---------

$input-placeholder-color: $dark-gray;
$select-radius: $global-radius;
$input-radius: $global-radius;
$form-button-radius: $global-radius;

// 21. Label
// ---------

$label-radius: $global-radius;

// 27. Pagination
// --------------

$pagination-radius: $global-radius;

// 28. Progress Bar
// ----------------

$progress-radius: $global-radius;

// 52. Thumbnail
// -------------

$thumbnail-radius: $global-radius;

// 53. Title Bar
// -------------

$titlebar-padding: .5rem;
