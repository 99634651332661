.card--list__item{
  flex-direction: column-reverse;
  padding-left: 0;
  margin-bottom: 2rem;

  @include breakpoint(medium){
    flex-direction: row;
    margin-bottom: 0;
  }
}

.card--list__text{
  padding-left: 1rem;
  padding-right: 1rem;

  .tags{
    margin-top: 2rem;
  }
}

a.card__title,
a .card__title{
  &:hover{
    text-decoration: none;
  }
}

.card__link{
  &:hover{
    text-decoration: none;
  }
}

.card--full{
  .card__title{
    &:hover{
      text-decoration: none;
    }
  }
}

.card__support{
  margin-left: auto;
  margin-right: auto;
}
