.main-footer,
.mini-footer{
  color: $dark-gray;
  background-color: $riihimaki-light;

  a{
    color: $anchor-color;
    font-weight: $global-weight-bold;

    &:hover,
    &:focus{
      color: $anchor-color-hover;
      text-decoration: none;
    }
  }
}

.main-footer{
  > .row:first-child{
    position: relative;

    &::before{
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      right: 15px;
      top: 0;
      width: 43px;
      height: 60px;
      background-image: url(images/rsign-puzzle.svg);
      background-size: auto 100%;
      background-repeat: no-repeat;

      @include breakpoint(medium){
        width: 109px;
        height: 150px;
      }
    }
  }

  .logo-wrapper{
    margin-bottom: 2rem;
  }
}

.footer-feedback{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $riihimaki-main;
  color: $white;
  padding: 1rem 0;

  > *{
    margin: 0 1rem;
  }

  a{
    color: $white;
  }

  .button.hollow{
    color: $white;
    border-color: $white;
  }
}

.main__footer__nav{
  text-align: left;
}

.footer-nav{
  margin: 0;

  li{
    display: block;
    padding: 0;
  }
}

.footer-extra-links{
  margin-top: 2rem;

  @include breakpoint(medium){
    margin-top: 0;
  }
}

.main__footer__social{
  margin-top: 1rem;
  text-align: center;

  @include breakpoint(medium){
    text-align: left;
  }

  .icon{
    width: 22px;
    height: 22px;
  }
}
