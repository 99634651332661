// Brand
$riihimaki-main: #eb054b;
$riihimaki-main-rgb: 235,5,75;
$riihimaki-pool: #235aa0;
$riihimaki-pool-rgb: 35,90,160;
$riihimaki-park: #5ad2be;
$riihimaki-park-rgb: 90,210,190;
$riihimaki-sunflower: #f5dc14;
$riihimaki-sunflower-rgb: 245,220,20;
$riihimaki-statue: #ff8c9b;
$riihimaki-statue-rgb: 225,140,155;
$riihimaki-robot: #9b0073;
$riihimaki-robot-rgb: 155,0,115;

// Other colors
$riihimaki-white: #fff;
$riihimaki-white-rgb: 255,255,255;
$riihimaki-light: #fff6f8;
$riihimaki-light-rgb: 255,246,248;
$riihimaki-black: #000;
$riihimaki-black-rgb: 0,0,0;
$riihimaki-link: $riihimaki-pool;
$riihimaki-link-hover: lighten($riihimaki-pool, 10%);
$dark-gray: #5d5d5d;
$light-gray: #fafafa;
$lighter-gray: #d6d6d6;

// Decidim variables
$primary: $riihimaki-main;
$primary-rgb: $riihimaki-main-rgb;
$secondary: $riihimaki-pool;
$secondary-rgb: $riihimaki-pool-rgb;
$success: #248474; // a11y color contrast
$success-rgb: 36,132,116;
$warning: #847506; // a11y color contrast
$warning-rgb: 132,117,6;
$alert: #eb001f; // a11y color contrast
$alert-rgb: 235,0,31;
$highlight: #be6400;
$highlight-rgb: 190,100,0;
$highlight-alternative: #be6400;
$highlight-alternative-rgb: 190,100,0;

$proposals: $primary;
$actions: $secondary;
$debates: $riihimaki-pool;
$meetings: $riihimaki-sunflower;

$twitter: #55acee;
$facebook: #3b5998;
$google: #dd4b39;
