.title-action{
  flex-direction: column;
  align-items: baseline;

  @include breakpoint(medium){
    flex-direction: row;
    align-items: center;
  }
}

.title-action__title{
  @include breakpoint(medium){
    margin-right: auto;
  }
}

.title-action__action{
  margin-top: 1rem;
  margin-left: 0;

  @include breakpoint(medium){
    margin-top: 0;
    margin-left: 1rem;
  }
}
