$navbar-color: $riihimaki-main;
$navbar-color-hover: $body-font-color;
$navbar-color-active: $riihimaki-main;
$offcanvas-navbar-color: rgba(white, .5);
$offcanvas-navbar-color-hover: $white;
$navbar-bg: $white;
$navbar-bg-hover: $white;
$navbar-active-bg: $white;
$navbar-search-border: $dark-gray;
$navbar-search-color: $dark-gray;
$navbar-font-size: 1.5rem;
$tagline-font-size: .75rem;
$tagline-font-size-medium: 1.4rem;
$titlebar-bg: $riihimaki-main;
$titlebar-color: $white;
$titlebar-link: $white;
$titlebar-link-active: $white;
$titlebar-link-spacing: 1rem;

.navbar{
  background-color: $navbar-bg;

  @include breakpoint(medium){
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: $navbar-font-size;
    border-bottom: .125rem solid $medium-gray;
  }
}

.off-canvas{
  .navbar{
    > .column{
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.off-canvas-content{
  .main-nav ul{
    @include breakpoint(medium){
      justify-content: flex-start;

      > li{
        flex: 0 auto;
        margin-right: 2*$titlebar-link-spacing;
      }
    }
  }

  .main-nav__link a{
    display: block;
    padding: .4em 2em;
    color: $navbar-color;
    font-weight: $global-weight-bold;

    &:hover,
    &:focus{
      color: $navbar-color-hover;
    }
  }

  .main-nav__link--active a{
    box-shadow: none;
    color: $navbar-color-active;
    text-decoration: underline;
  }
}

.application-bar{
  padding: .4rem 0;
  border-bottom: 2px solid $lighter-gray;
}

.application-bar__name{
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3em;

  @include breakpoint(medium){
    font-size: 1.5em;
  }
}

.title-bar{
  background: $titlebar-bg;
  color: $titlebar-color;

  &::after{
    @media (min-width: 1400px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 70px;
      background-color: #fff;
      background-image: url(images/rsign-red.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: auto 100%;
    }
  }

  a:focus{
    border-top: 0;
    border-bottom: 0;
  }
}

.topbar{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div{
    align-items: center;
  }
}

.topbar-navigation{
  display: flex;
  flex-direction: column-reverse;
}

.topbar-controls{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .topbar-links{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .topbar-search{
    padding-left: 15px;
  }
}

.topbar__dropmenu .dropdown.menu > li.is-active > a{
  color: $white;
}

.topbar__dropmenu > ul > li > a,
.topbar__dropmenu > ul > li > a::after,
.topbar__user__login a{
  @include breakpoint(medium){
    color: $titlebar-link;
  }
}

.topbar__user{
  padding-right: 0;
}

.topbar__user__logged{
  padding-right: 0;

  .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
    @include css-triangle($dropdownmenu-arrow-size, $white, down);
  }

  .topbar__notifications,
  .topbar__conversations{
    @include breakpoint(smallmedium down){
      display: none;
    }
  }
}

/* Menu icon and off-canvas */
.topbar__menu{
  font-size: 2rem;
}

.topbar__search{
  position: relative;

  input{
    color: $input-color;
    border: $input-border;
    background: $input-background;
    border-radius: $input-radius 0 0 $input-radius;

    &:focus{
      color: $input-color;
      background: $input-background-focus;
      box-shadow: none;
    }

    @include breakpoint(medium){
      background: $input-background;
    }
  }

  &:not(:focus-within){
    button{
      background: var(--primary);
      color: $white;
    }
  }

  &:focus-within{
    input{
      background-color: $light-gray;
      color: $body-font-color;
      border: $input-border;

      &::placeholder{
        color: $dark-medium-gray;
      }
    }
  }

  .input-group{
    button{
      border-radius: 0 10px 10px 0;

      @include breakpoint(medium only){
        border-radius: 5px;
      }
    }

    .input-group-field{
      @include breakpoint(medium only){
        display: none;
      }
    }

    .input-group-button{
      @include breakpoint(medium only){
        height: auto;
      }
    }
  }
}

.logo-wrapper{
  img{
    display: block;
    max-height: 60px;

    @include breakpoint(mediumlarge){
      max-height: 70px;
    }
  }

  .logo-inner{
    display: inline-block;
  }

  .logo-tagline{
    margin-top: 3px;
    text-align: center;
    font-family: $info-font-family;
    font-weight: $global-weight-normal;
    font-size: $tagline-font-size;
    text-transform: uppercase;
    line-height: 1;

    @include breakpoint(mediumlarge){
      font-size: $tagline-font-size-medium;
    }

    a{
      color: $body-font-color;
    }
  }
}

.topbar__notifications .icon,
.topbar__conversations .icon{
  opacity: 1;
}

.topbar__edit__link{
  margin-left: 2*$titlebar-link-spacing;

  a{
    color: $titlebar-link;

    &:hover{
      color: $titlebar-link-active;
    }
  }
}
