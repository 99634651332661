@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700; // bold
  font-display: swap;
  src: url(fonts/Raleway-Bold-latin.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400; // normal
  font-display: swap;
  src: url(fonts/OpenSans-Regular-latin.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400; // normal
  font-display: swap;
  src: url(fonts/OpenSans-Italic-latin.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600; // semi-bold
  font-display: swap;
  src: url(fonts/OpenSans-SemiBold-latin.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600; // semi-bold
  font-display: swap;
  src: url(fonts/OpenSans-SemiBoldItalic-latin.woff2) format('woff2');
}
