.budget-list{
  &__item{
    @include breakpoint(smallmedium down){
      flex-direction: column-reverse;
    }
  }

  &__data{
    flex-direction: column;
    flex-basis: 16rem;
    padding: 1rem;
    justify-content: center;

    &__show{
      margin: 1rem 0 1.5rem;
    }

    &__number{
      flex-grow: 0;
      padding-bottom: 1.5rem;

      @include breakpoint(smallmedium down){
        padding-bottom: 0;
      }
    }

    @include breakpoint(smallmedium down){
      flex-basis: 4rem;
    }
  }

  &__action{
    border-radius: 1%;
    width: 3em;
  }
}
